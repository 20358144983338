import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import Banner from '../../../components/features/Banner'
import Question from '../../../components/features/Question'
import Video from '../../../components/features/Video'
import Info from '../../../components/features/Info'
import Steps from '../../../components/features/Steps'
import SEO from '../../../components/common/SEO';
import FeatureCards from "../../../components/features/FeatureCards"
import { Link } from "gatsby"

export const query = graphql`
  query CertificateTracking {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
    const [toggler, setToggler] = useState(false)
    const lightboxProps = {
        toggler: toggler,
        setToggler: setToggler,
        sources: ["https://www.youtube.com/watch?v=VOSUNYrSGvo"],
    }

    const bannerProps = {
        title: 'Certificate Tracking',
        subtitle: "Delete your spreadsheets! Seriously, we developed this feature so that your compliance team can do just that. Workers can upload digital copies of their off-site training and compliance certificates themselves, saving you the hassle of doing it (but let's be honest, you'll still have to collect and upload a few of them).",
        featureName: 'certificateTracking',
        rightButtonText: 'Why Certificate Tracking Software?',
        rightButtonSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
        mockupImg: '/features/certificatetracking/feature-page-header-certificates.png'
    }

    const questionProps = {
        question: 'Pulling out an invalid ticket is embarrassing.',
        answer: "Worksite gatekeepers can sometimes be forgiving, but Weigh Scale Operators and Police Officers can be downright rude. If only your compliance team had reviewed Jay's driver's license and made sure it had an airbrake endorsement before Officer Park discovered it. It sure would have been nice to have recorded the proper expiry date so that a system could remind you and Fire Warden Francis that his first aid ticket was coming due. What about Dock Worker Dalia, who didn't even know she needed a TDG ticket in the first place?",
        // answerBulletList: [
        //     'No way to assure prompt readership',
        //     'Nearly impossible to audit who received and who read',
        //     'Hard to get feedback on messages',
        //     'Tough to classify or target teams / locations',
        // ],
        imageSrc: '/features/certificatetracking/Certificates.jpg',
        imgAlt: 'Cartoon of an employee pulling out a wallet of expired tickets'
    }

    const videoProps = {
        videoSrc: 'https://www.youtube.com/watch?v=VOSUNYrSGvo',
        videoImage: '/features/certificatetracking/youtubethumb.png',
        videoStatement: (<strong>Certificate Tracking</strong>),
    }

    const infoProps = {
        infoList: [
            {
                mockupImage: '/features/certificatetracking/feature-large-certificates-1.jpg',
                infoTitle: 'Assign Certification Requirements',
                infoSubtitle: 'The Workhub software has hundreds of online training videos, but there will always be the need for workers to go elsewhere to prove they know how to resuscitate a chest-compression dummy or quickly don a self-contained breathing apparatus. They must invariably return with a piece of paper to prove they completed the training.',
                infoBullets: [
                    {
                        name: 'Assign to Workers: ',
                        description: 'Decide which roles in your organization require which tickets, set default expiry terms (or no expiry) or make certificates optional.'
                    },
                    {
                        name: 'Recommend Training Providers:',
                        description: 'In the event your workers need to go elsewhere for training, you may wish to recommend your preferred provider, provide workers with a PO# or give them special instructions.',
                    },
                    {
                        name: 'Automated Notifications:',
                        description: `Workers will be shown and notified of their missing tickets and will be reminded to upload scanned documents or pictures from their phone. Supervisors can also track the status of their subordinates' tickets.`,
                    }
                ],
            },
            {
                mockupImage: '/features/certificatetracking/feature-large-certificates-2.jpg',
                infoTitle: 'Manage thousands of certificates with ease',
                // infoSubtitle: 'Easily configure who will receive the bulletin and set how long you would like them to review the note before the status is changed. Set an Archive Date to configure how bulletins are handled for new hires or simply turn compliance off for less important updates.',
                infoBullets: [
                    {
                        name: 'Approve Submitted Certificates: ',
                        description: `Your dashboard will show a count of pending certificate submissions, so it's easy to scroll through, review and approve them.`
                    },
                    {
                        name: 'Decline the Not-so-Good Ones:',
                        description: `Occasionally you'll need to decline a ticket that's been scanned on the back side, or ask for a newer certification when an expired one gets sent in. The worker will be notified along with the reason you gave for the decline.`,
                    },
                ],
            },
            {
                mockupImage: '/features/certificatetracking/feature-large-certificates-3.jpg',
                infoTitle: 'Manage Training Sessions',
                // infoSubtitle: 'Easily configure who will receive the bulletin and set how long you would like them to review the note before the status is changed. Set an Archive Date to configure how bulletins are handled for new hires or simply turn compliance off for less important updates.',
                infoBullets: [
                    {
                        name: 'Schedule Training Sessions:',
                        description: `Coming Due and Past Due reports make it easy to identify workers that need to be certified.`
                    },
                    {
                        name: 'Invite Workers:',
                        description: `Save money and get everyone certified immediately by setting up a training session next Friday afternoon. The system will send an email invite the worker and place a reminder on their dashboard.`,
                    },
                    {
                        name: 'Keep Supervisors Abreast:',
                        description: `The workers' supervisors will also be notified. Supervisor Sue might otherwise wonder why Juan is not at the worksite that morning.`,
                    },
                ],
            }
        ]
    }

    const stepsProps = {
        title: 'Tracking Certificates is as Easy as 1, 2, 3',
        // subtitle: 'Updating your workers on all devices/platforms and tracking readership has never been easier',
        stepList: [
            {
                title: 'Check the Dashboard',
                subtitle: 'Quickly see assigned certificates that require attention',
                imageSrc: '/features/certificatetracking/certificate-steps-1.jpg'
            },
            {
                title: 'Upload your Tickets',
                subtitle: 'Drag PDF or image files, add issue and expiry dates.',
                imageSrc: '/features/certificatetracking/certificate-steps-2.jpg'
            },
            {
                title: 'Gain Compliance',
                subtitle: 'An administrator will review and approve your submission',
                imageSrc: '/features/certificatetracking/certificate-steps-3.jpg'
            }
        ]
    }

    const seo = {
        title: 'Workhub | Free Certificate Tracking Licenses Training Tickets',
        description: 'Our Certificate Tracking software makes it easy to manage employee licenses, tickets, training & certifications. Track expiration dates, get notifications & manage approval.',
        image: '/features/certificatetracking/social-card.jpg',
        // pathname,
        // article
    }

    const featureIndex = [
        {
            title: "Upload",
            para: "Upload digital copies of employees' off-site training and compliance certificates.",
        },
        {
            title: "Assign ",
            para: "Decide which roles in your organization require tickets. Set default expiry terms or make certificates optional.",
        },
        {
            title: "Recommend Training Providers ",
            para: "In the event workers need to go elsewhere for their training you can recommend a preferred provider, supply workers with a PO# or issue special instructions.",
        },
        {
            title: "Notifications",
            para: "Workers will be shown and notified of their missing tickets and will be reminded to upload scanned documents or pictures from their phones. Supervisors can also track the status of their subordinates' tickets.",
        },
        {
            title: "Approval",
            para: "Your dashboard will show a count of pending certificate submissions, so it is easy to scroll through, review and approve them.",
        },
        {
            title: "Schedule Training Sessions",
            para: "Coming Due and Past Due reports make it easy to identify workers that need to be certified.",
        },
        {
            title: "Invite Workers",
            para: "Save money and get everyone recertified immediately by setting up a training session. The system will send an email, invite the worker and place a reminder on their dashboard.",
        },
        
    ]

    return (
        <Layout {...lightboxProps}>
            <SEO {...seo} {...data.site} />
            <Banner {...bannerProps} {...lightboxProps} />
            <Question {...questionProps} />
            <Video {...videoProps} {...lightboxProps} />
            <Info {...infoProps} />
            <Steps {...stepsProps} />

            <section className="pricing_testimonial" style={{maxWidth: '1200px'}}>
        <blockquote style={{marginTop: '0px'}}>
          <div className="stars">
              <img src="/app-icons/partner-logos/star.png"/>
              <img src="/app-icons/partner-logos/star.png"/>
              <img src="/app-icons/partner-logos/star.png"/>
              <img src="/app-icons/partner-logos/star.png"/>
              <img src="/app-icons/partner-logos/star.png"/>
            </div>
          <p>

              "Workhub has been a <span className="span_gradient" style={{padding: '0px'}}>game changer</span> for all of my clients, big and small.  The certificate tracking is our most widely used component of Workhub, but the entire system is beyond compare."
 
          </p>
       
          <p className="partner_name" id="partner_name" style={{fontSize: '16px!important'}}><Link to="/resources/partners/infinity">DANA RODE - INFINITY DYNAMICS</Link></p>

          {/* <img className="testimonial_img" src="/app-icons/partner-logos/infinity.jpg"/> */}
        </blockquote>
      </section>

            {/* FEATURE OVERVIEW */}

            <section className="feature-overview-container">
                <h2>Overview</h2>

                <div className="feature-overview-grid" style={{ marginBottom: '60px' }}>
                    {featureIndex.map(item => (
                        <FeatureCards title={item.title} para={item.para} />
                    ))}
                </div>
            </section>


        </Layout>
    )
}